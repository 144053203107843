<template>
  <div class="hezuo">
    <div class="hezuocontent">
      <div
        v-for="(item,index) in hezuoArr.slice(0,3)"
        :key="index"
        class="hezuo-item hezuo-item-leng"
      >
        <img :src="'/hezuo/logo_'+item.name+'.png'">
      </div>
    </div>
     <div class="hezuocontent">
      <div
        v-for="(item,index) in hezuoArr.slice(3,8)"
        :key="index"
        class="hezuo-item "
      >
        <img :src="'/hezuo/logo_'+item.name+'.png'">
      </div>
    </div>

      <div class="hezuocontent">
      <div
        v-for="(item,index) in hezuoArr.slice(8,11)"
        :key="index"
        class="hezuo-item hezuo-item-leng"
      >
        <img :src="'/hezuo/logo_'+item.name+'.png'">
      </div>
    </div>
      
  </div>
</template>
<script>
export default {
  data() {
    return {
      hezuoArr: [
        { name: 'tx', status: false },
        { name: 'yuewen', status: false },
        { name: 'zijie', status: false },
        { name: 'jw', status: false },
        { name: 'zhang', status: false },
        { name: 'zzy', status: false },
        { name: 'ks', status: false },
        { name: 'baidu', status: false },
        { name: 'wy', status: false },
        { name: 'al', status: false },
        { name: 'fq', status: false }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.hezuo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hezuocontent{
  width: 80%;
  display: flex;
  justify-content: center;
}
.hezuo-item-leng{
  width:33%;
}
</style>